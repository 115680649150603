<template>
    <div id="course-management">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>课程管理</h1>
            <div class="search-bar">
                <div class="lf">
                    <div class="course-entrybox">
                        <i class="icon-search"></i>
                        <span>课程</span>
                        <input v-model="searchName" type="text">
                    </div>
                    <div class="course-status-options">
                        <el-select v-model="courseStatus">
                            <el-option
                                v-for="item in status"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="calendar">
                        <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                            popper-class="popDateBox"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="btn-search" @click="getCourseList(true)">查询</div>
                </div>
                <div class="rf" @click="release">新增课程</div>
            </div>
            <div class="table">
                <el-table
                        :data="courseList"
                        style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="课程名称">
                    </el-table-column>
                    <el-table-column
                        prop="studentCount"
                        align="center"
                        width="100"
                        label="学生数量">
                    </el-table-column>
                    <el-table-column
                        prop="coursewareCount"
                        align="center"
                        width="100"
                        label="课件数量">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        align="center"
                        label="新增时间">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        align="center"
                        width="120"
                        label="审核状态">
                        <template slot-scope="scope" class="table-status">
                            <span :style="{'color': scope.row.status === 1 ? '#1DC084' : scope.row.status === -2 ? '#DC000C' : '#666666'}" :class="scope.row.status === 2 ? 'off-shelf' : ''">
                                {{ scope.row.status === 0 ? '待审核' : scope.row.status === 1 ? '上架中' : "已下架" }}
                            </span>
                            <div class="off-shelf-reason" v-if="scope.row.status === -2">{{ scope.row.remark }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        width="280"
                        label="操作"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)"
                                v-if="scope.row.status !== 1"
                            >编辑</el-button>
                            <el-button
                                size="mini"
                                @click="handleCheckCourseware(scope.$index, scope.row)"
                            >课件</el-button>
                            <el-button
                                    size="mini"
                                    @click="handleDetail(scope.$index, scope.row)"
                            >详情</el-button>
                            <el-button v-if="scope.row.status !== 1" size="mini" @click="removeCourse(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="courseList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.createTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span :style="{'color': rowData.prop.status === 1 ? '#1DC084' : rowData.prop.status === -2 ? '#DC000C' : '#666666','text-decoration':rowData.prop.status === -2 ? 'underline' : 'none'}" :class="rowData.prop.status === 2 ? 'off-shelf' : ''">
                            {{ rowData.prop.status === 0 ? '待审核' : rowData.prop.status === 1 ? '上架中' : "已下架" }}
                        </span>
                    </template>
                    <template v-slot:btn="rowData">
                        <span
                            @click="handleEdit(rowData.index,rowData.prop)"
                            v-if="rowData.prop.status !== 1"
                        >
                            编辑
                        </span>
                        <span
                            @click="handleCheckCourseware(rowData.index,rowData.prop)"
                        >
                            课件
                        </span>
                        <span
                            @click="handleDetail(rowData.index,rowData.prop)"
                        >
                            详情
                        </span>
                        <span
                            @click="removeCourse(rowData.prop.id)"
                            v-if="rowData.prop.status !== 1"
                        >
                            删除
                        </span>
                    </template>
                </listH5Component>
                <div class="pagination" v-if="total > 10">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="changeCourseList"
                        :current-page.sync="page"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                :title="editingType === editingButtons[2] ? '课程详情' : editingType === editingButtons[1] ? '课件管理' : editing ? '编辑课程' : '新增课程'"
                top="5vh"
                width="720px"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                @closed="closed"
                class="dialog"
            >
                <!-- 编辑 -->
                <el-form :model="form" v-if="editingType === editingButtons[0]">
                    <el-form-item label="课程名称" :label-width="formLabelWidth">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="课程封面" size="mini" :label-width="formLabelWidth">
                        <el-upload v-if="!form.cover"
                                   ref="cnLogo" action=""
                                   accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
                                   :auto-upload="false" :show-file-list="false"
                                   :on-change="chooseCoverImage">
                            <el-button type="warning" icon="el-icon-folder-opened">上传课程封面图</el-button>
                        </el-upload>
                        <el-button-group v-else>
                            <el-button type="primary" icon="el-icon-view" @click="showPreviewCoverImage">查看课程封面图</el-button>
                            <el-button type="danger" icon="el-icon-close" @click="removeCoverImage"></el-button>
                        </el-button-group>
                    </el-form-item>
                    <el-form-item label="课程描述" :label-width="formLabelWidth">
                        <el-input type="textarea" v-model="form.description"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" v-if="editingType === 'EDIT'">
                    <el-button type="primary" :loading.sync="btnLoadingSave" @click="saveCourse">{{ editing ? '保存' : '新增' }}</el-button>
                </div>

                <!-- 课件 -->
                <div class="courseware" v-if="editingType === editingButtons[1]">
                    <div class="binding-course">
                        <div class="course">课程名称：<span>{{ courseList[courseIndex].name }}</span></div>
                        <div class="binded-list">
                            <label>已绑定列表</label>
                            <el-table
                                :data="coursewareList"
                                tooltip-effect="dark"
                                style="width: 100%"
                            >
                                <el-table-column
                                    label="排序"
                                    prop="sort"
                                    width="55"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="名称"
                                    prop="name"
                                >
                                </el-table-column>
                                <el-table-column
                                    align="right">
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            @click="handleRecourseDetail(scope.$index, scope.row)"
                                        >详情</el-button>
                                        <el-button
                                            type="text"
                                            @click="unbindClick(scope.$index, scope.row)"
                                        >解绑</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="courseware-pagination" v-if="coursewareTotal > 5">
                                <el-pagination
                                        small
                                        layout="prev, pager, next"
                                        @current-change="findCourseware"
                                        :current-page.sync="coursewarePage"
                                        :page-size="coursewarePagesize"
                                        :total="coursewareTotal"
                                ></el-pagination>
                            </div>
                        </div>
                        <div class="courseware-form">
                            <label>添加课件列表</label>
                            <div class="search-bar">
                                <span>搜 索</span>
                                <el-input v-model="searchCourseware" placeholder="请输入课件名称" clearable></el-input>
                                <el-date-picker
                                        popper-class="popDateBox"
                                        v-model="unbindSearchDate"
                                        type="daterange"
                                        range-separator="~"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期" style="width: 700px; margin: 0 10px;">
                                </el-date-picker>
                                <el-button type="primary" @click="handleSearchBindingCourseware">查询</el-button>
                            </div>
                            <el-table
                                ref="mutipleTable"
                                :data="coursewareBindingList"
                                tooltip-effect="dark"
                                style="width: 100%"
                                @selection-change="chooseCourseware"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="名称"
                                    prop="name"
                                >
                                </el-table-column>
                                <el-table-column
                                        align="right">
                                    <template slot-scope="scope">
                                        <el-button
                                                type="text"
                                                @click="handleRecourseDetail(scope.$index, scope.row)"
                                        >详情</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="courseware-pagination">
                                <span>已选{{ addCoursewareOptions.length }}个</span>
                                <el-pagination v-if="bindingCoursewareTotal > 5"
                                    small
                                    layout="prev, pager, next"
                                    @current-change="handleSearchBindingCourseware"
                                    :current-page.sync="bindingCoursewarePage"
                                    :page-size="coursewarePagesize"
                                    :total="bindingCoursewareTotal"
                                ></el-pagination>
                            </div>
                        </div>
                        <div class="btn">
                            <el-button type="primary" @click="bindCourseware">提交</el-button>
                        </div>
                    </div>
                </div>

                <!-- 详情 -->
                <div class="course-detail" v-if="editingType === editingButtons[2]">
                    <ul>
                        <li>
                            <label>课程名称</label>
                            <div>{{ courseList[courseIndex].name }}</div>
                        </li>
                        <li>
                            <label>新增时间</label>
                            <div>{{ courseList[courseIndex].createTime | formatDate }}</div>
                        </li>
                        <li>
                            <label>课程描述</label>
                            <div>{{ courseList[courseIndex].description }}</div>
                        </li>
                        <li>
                            <label>课件资源</label>
                            <div>
                                <el-table
                                    border
                                    :data="coursewareList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="名称"
                                        prop="name"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="操作"
                                        width="60"
                                    >
                                        <template slot-scope="scope">
                                            <span style="color: #409EFF; cursor: pointer;" @click="handleRecourseDetail(scope.$index, scope.row)">预览</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="courseware-pagination" v-if="coursewareTotal > 5" style="width: 100%;">
                                    <el-pagination
                                            small
                                            layout="prev, pager, next"
                                            @current-change="findCourseware"
                                            :current-page.sync="coursewarePage"
                                            :page-size="coursewarePagesize"
                                            :total="coursewareTotal"
                                            style="float: right; width: max-content; margin-top: 5px;"
                                    ></el-pagination>
                                </div>
                            </div>
                        </li>
                        <li>
                            <label>学生列表</label>
                            <div>
                                <el-table
                                    border
                                    :data="studentList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="序号"
                                        type="index"
                                        width="55"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="学生账号"
                                        prop="userName"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="姓名"
                                        prop="userContact"
                                    >
                                    </el-table-column>
                                </el-table>
                                <div class="courseware-pagination" v-if="studentListTotal > 5" style="width: 100%;">
                                    <el-pagination
                                            small
                                            layout="prev, pager, next"
                                            @current-change="findStudent(courseList[courseIndex].id)"
                                            :current-page.sync="studentBindingPage"
                                            :page-size="studentBindingPagesize"
                                            :total="studentListTotal"
                                            style="float: right; width: max-content; margin-top: 5px;"
                                    ></el-pagination>
                                </div>
                            </div>
                        </li>
                        <li id = "js_remark" v-if = "courseList[courseIndex].remark != ''">
                            <label>备注</label>
                            <div>{{ courseList[courseIndex].remark }}</div>
                        </li>
                    </ul>
                </div>
            </el-dialog>

            <!-- 解绑课件 -->
            <el-dialog
                    title="确认要解绑该课件吗"
                    :visible.sync="unbindDialogVisible"
                    :close-on-click-modal="false"
                    width="30%">
                <span>{{ coursewareIndex !== null && coursewareList[coursewareIndex] ? coursewareList[coursewareIndex].name : '' }}</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="unbindDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleUnbunding">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 课件资源详情 -->
            <el-dialog
                    title="课件资源详情"
                    :visible.sync="isShowCoursewareDetail"
                    :close-on-click-modal="false">
                <el-row>
                    <el-form size="small" label-position="right" label-width="120px">
                        <el-form-item label="名称">{{ coursewareDetail.name }}</el-form-item>
                        <el-form-item label="文档描述">{{ coursewareDetail.description }}</el-form-item>
                        <el-form-item label="课件上传">
                            <el-row type="flex">
                                {{ coursewareDetail.textName }}
                                <el-link type="primary" @click="previewFileByOpenNewBlank(coursewareDetail.id, 'previewText')" style="margin-left: 50px;">下载</el-link>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="视频上传">

                            <el-row type="flex">
                                {{ coursewareDetail.videoName }}
                                <el-link type="primary" @click="previewFileByOpenNewBlank(coursewareDetail.id, 'previewVideo')" style="margin-left: 50px;">下载</el-link>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="课件排序">{{ coursewareDetail.sort }}</el-form-item>
                    </el-form>
                </el-row>
            </el-dialog>

            <!-- 课程封面图 -->
            <img-cropper v-model="isShowImgCropper"
                         top="10vh" width="805px" cropWidth="765px" cropHeight="480px"
                         :cfg="imgCropperCfg" @cropped="uploadCroppedImage"></img-cropper>
            <el-dialog
                    width="685px"
                    title="查看课程封面图"
                    :visible.sync="isShowPreviewCoverImage"
                    :close-on-click-modal="false">
                <el-row>
                    <img :src="imgCover" style="width: 645px; height: 360px;">
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import {urls, banbaoUrl} from '@/lib/common'
    import imgCropper from '@/components/imgCropper';
    import Momemt from "moment";
    import listH5Component from './component/listH5Component'

    export default {
        name: "CourseManagement",
        components: {
            LeftbarComponent,
            TopbarComponent,
            imgCropper,
            listH5Component
        },
        data() {
            return {
                banbaoUrl,
                userinfo: '',
                page: 1,
                pageSize: 10,
                total: 0,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '课程管理',
                        disabled: true,
                        href: '/courseManagement',
                    }
                ],
                courseList: [], // 课程列表
                courseIndex: null,
                searchName: '',
                searchDate: '',
                courseStatus: '', //  -2已下架，-1审核失败，0待审核，1上架中

                dialogVisible: false,
                unbindDialogVisible: false,

                coursewareBindingList: [], // 已绑定列表
                coursewareBindingIndex: '', // 已绑定列表选中下标

                coursewareList: [], // 添加课件列表
                coursewareIndex: null,
                bindingCoursewarePage: 1, // 绑定课程列表页码
                bindingCoursewareTotal: 0, // 绑定课程列表总数
                studentList: [],
                studentListTotal: 0,
                studentBindingPage: 1, // 学生列表
                studentBindingPagesize: 5,
                editingButtons: ['EDIT', 'COURSEWARE', 'DETAIL', 'RECOURSE'],
                editing: false,
                editingType: '',
                formLabelWidth: '120px',
                form: {
                    id: '',
                    name: '',
                    description: '',
                },
                status: [
                    {
                        label: '课程状态',
                        value: ''
                    }, {
                        label: '上架中',
                        value: 1
                    }, {
                        label: '待审核',
                        value: 0
                    },
                    // {
                    //     label: '审核失败',
                    //     value: -1
                    // },
                    {
                        label: '已下架',
                        value: -2
                    }
                ],
                options: [
                    {
                        value: '',
                        label: '选择'
                    }, {
                        value: 'file',
                        label: '文档'
                    }, {
                        value: 'video',
                        label: '视频'
                    }, {
                        value: 'audio',
                        label: '音频'
                    }
                ],
                searchCourseware: '',
                addCoursewareOptions: [],
                coursewarePagesize: 5, // 绑定课程列表显示列表长度
                coursewarePage: 1, // 添加课程列表页码
                coursewareTotal: 0, // 添加课程列表总数
                coursewareBindingPage: 1,
                coursewareDetail: {},

                unbindSearchDate: [],
                // 课件详情
                isShowCoursewareDetail: false,
                // 课程封面图
                isShowImgCropper: false,
                imgCropperCfg: {},
                isShowPreviewCoverImage: false,
                imgCover: '',

                btnLoadingSave: false,
            }
        },
        created() {
            if (sessionStorage.getItem('userinfo')) {
                this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
            } else if (this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})) {
                this.userinfo = {
                    userId: this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})
                }
            }
            this.getCourseList();
        },
        methods: {
            getCourseList(forSearch = false) { // 获取课程列表
                this.page = forSearch ? 1 : this.page;
                const params = {
                    pageCurrent: this.page,
                    pageSize: this.pageSize
                }
                if (this.searchName) {
                    params.name = this.searchName
                }
                if (this.courseStatus !== '' && this.courseStatus !== undefined) {
                    params.status = this.courseStatus
                }
                if (this.searchDate) {
                    const start = this.searchDate[0];
                    const end = this.searchDate[1];
                    const startDate = `${this.dateFormat(start)} 00:00:00`;
                    const endDate = `${this.dateFormat(end)} 23:59:59`;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
                this.$http.get(urls.courseList, {params}).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.courseList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message)
                    }
                })
            },
            saveCourse() { // 新增/保存
                // console.log('saveCourse', this.form);
                if (!this.form.name) {
                    this.$message.warning("请输入课程名称")
                    return false
                }
                if (!this.form.description) {
                    this.$message.warning("请输入课程描述")
                    return false
                }
                const params = {
                    name: this.form.name,
                    description: this.form.description,
                    cover: this.form.cover ? this.form.cover : ''
                }
                if (this.editing && this.form.id) {
                    params.id = this.form.id;
                }

                this.btnLoadingSave = true;
                this.$http.post( this.editing ? urls.courseEdit : urls.courseSave, params).then(res => {
                    // console.log(res);
                    this.btnLoadingSave = false;
                    if (res.data.status === 200) {
                        this.$message.success(this.editing ? "修改课程成功" : '新增课程成功');
                        this.getCourseList();
                        this.dialogVisible = false;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
            handleEdit(index, row) {
                // console.log('handleEdit', index, row)
                // this.editingType = this.editingButtons[0];
                // this.editing = true;
                // this.form.id = row.id;
                // this.form.name = row.name;
                // this.form.description = row.description;
                // this.dialogVisible = true;

                let that = this;

                that.$http.get(`${urls.userServer.courseFrontFind}/${row.id}`).then((res) => {
                    if(res.data.status === 200) {
                        that.form = {
                            ...res.data.data,
                        };

                        this.editingType = this.editingButtons[0];
                        this.editing = true;
                        this.dialogVisible = true;
                    }
                });
            },
            changeCourseList(page) { // 切换课程管理页码
                this.page = page;
                this.getCourseList();
            },
            handleSearchBindingCourseware() { // 搜索课件名称
                let params = {
                    pageCurrent: this.bindingCoursewarePage,
                    pageSize: this.coursewarePagesize,

                    teacherId: this.userinfo.userId,
                    name: this.searchCourseware,
                    startDate: this.unbindSearchDate && this.unbindSearchDate.length ? `${Momemt(this.unbindSearchDate[0]).format('YYYY-MM-DD')} 00:00:00` : '',
                    endDate: this.unbindSearchDate && this.unbindSearchDate.length ? `${Momemt(this.unbindSearchDate[1]).format('YYYY-MM-DD')} 23:59:59` : ''
                }
                this.$http.get(urls.userServer.courseFrontCoursewareBindList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.coursewareBindingList = res.data.data.records;
                        this.bindingCoursewareTotal = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            handleCheckCourseware(index, row) { // 查看课件
                console.log('handleCheckCourseware', index, row)
                this.editingType = this.editingButtons[1];
                this.searchCourseware = '';
                this.unbindSearchDate = [];
                this.courseIndex = index;
                this.dialogVisible = true;

                this.coursewarePage = 1;
                this.findCourseware();
                this.coursewareBindingPage = 1;
                this.getCoursewareBindingList();
            },
            getCoursewareBindingList() { // 获取绑定课件列表
                let params = {
                    pageCurrent: this.bindingCoursewarePage,
                    pageSize: this.coursewarePagesize,
                    teacherId: this.userinfo.userId
                }
                this.dialogVisible = true;
                if (this.searchCourseware) params.searchCourseware = this.searchCourseware;
                this.$http.get(urls.coursewareBindList, {params}).then(res => {
                    // console.log(res);
                    if (res.data.status === 200) {
                        this.coursewareBindingList = res.data.data.records;
                        this.bindingCoursewareTotal = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            findCourseware() { // 查找课程相关课件
                const params = {
                    courseId: this.courseList[this.courseIndex].id,
                    pageCurrent: this.coursewarePage,
                    pageSize: this.coursewarePagesize
                }
                this.$http.get(urls.findCourseware, {params}).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.coursewareList = res.data.data.records;
                        this.coursewareTotal = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            findStudent(courseId) {
                let params = {
                    courseId,
                    pageCurrent: this.studentBindingPage,
                    pageSize: this.studentBindingPagesize
                }
                this.$http.get(urls.findStudent, {params}).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.studentList = res.data.data.records;
                        this.studentListTotal = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            handleDetail(index, row) {
                let that = this;

                that.$http.get(`${urls.userServer.courseFrontFind}/${row.id}`).then((res) => {
                    if(res.data.status === 200) {
                        that.coursewareDetail = {
                            ...res.data.data,
                        };

                        this.editingType = this.editingButtons[2];
                        this.courseIndex = index;
                        this.findCourseware();
                        this.findStudent(row.id);
                        this.dialogVisible = true;
                    }
                });
            },
            release() {
                // console.log('release');
                this.form.name = '';
                this.form.description = '';
                this.editing = false;
                this.editingType = this.editingButtons[0];
                this.dialogVisible = true;
            },
            chooseCourseware(e) {
                console.log('chooseCourseware', e);
                this.addCoursewareOptions = e;
            },
            changeBindingCoursewarePage(page = 1) {
                console.log('changeBindingCoursewarePage', this.bindingCoursewarePage);
                this.bindingCoursewarePage = page;
                this.getCoursewareBindingList();
            },
            changeCoursewarePage(page = 1) {
                console.log('changeCoursewarePage', this.coursewarePage)
                this.coursewarePage = page;
                this.findCourseware();
            },
            filePreview(index, row) { // 文件预览
                console.log('filePreview', index, row);
            },
            handleRecourseDetail(index, row) {// 课件资源详情
                let that = this;

                that.$http.get(`${urls.coursewareFind}${row.id}`).then((res) => {
                    if(res.data.status === 200) {
                        that.coursewareDetail = {
                            ...res.data.data,
                        };
                        that.isShowCoursewareDetail = true;
                    }
                });
            },
            unbindClick(index, row) { // 点击解绑
                this.unbindDialogVisible = true;
                this.coursewareIndex = index;
            },
            handleUnbunding() { // 确定解绑
                // console.log('handleUnbunding');
                // const query = `${this.courseList[this.courseIndex].id}/${this.coursewareList[this.coursewareIndex].id}`;
                this.$http.post(`${urls.unbind}${this.coursewareList[this.coursewareIndex].id}`).then(res => {
                    console.log(res)
                    this.unbindDialogVisible = false;
                    if (res.data.status === 200) {
                        this.$message.success('解绑成功');
                        this.coursewarePage = 1;
                        this.findCourseware();
                        this.getCoursewareBindingList();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            bindCourseware() {
                console.log('bindCourseware');
                const ids = this.addCoursewareOptions.map((item, index) => {
                    return item.id
                })
                if (ids.length === 0) {
                    this.$message.warning("请先选择要绑定课件")
                    return false
                }
                const params = {
                    coursewareIds: ids.join(),
                    id: this.courseList[this.courseIndex].id
                }
                console.log(params)

                this.$http.post(urls.coursewareBind, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('课件绑定成功!~');
                        this.coursewarePage = 1;
                        this.findCourseware();
                        this.getCoursewareBindingList();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            previewFileByOpenNewBlank: function(params, type) {
                let path = `${window.location.origin}/api/userServer/file/courseware/${type}/${params}?token=${this.$cookies.get('app.token', {domain: banbaoUrl, path: ''})}`
                window.open(path, 'blank');
            },

            removeCourse: function(id) {
                let that = this;

                that.$confirm('是否删除该课程', '确认信息', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确认删除',
                    cancelButtonText: '取消'
                }).then(() => {
                    that.$http.post(`${urls.userServer.courseFrontDel}/${id}`).then((res) => {
                        if(res.data.status === 200) {
                            that.$message.success('删除课程成功');
                            that.getCourseList();
                        } else {
                            that.$message.error('删除课程失败');
                        }
                    });
                }).catch();
            },

            chooseCoverImage: function(file) {
                let that = this;
                // console.log(file);return;

                let fileNameArr = file.name.split('.');
                if(fileNameArr.length < 2 || !that.man.fast.inArray(fileNameArr[fileNameArr.length - 1].toLowerCase(), ['jpg', 'jpeg', 'png',])) {
                    that.$message.error('不支持的格式');
                    return false;
                }

                let reader = new FileReader();
                reader.readAsDataURL(file.raw);
                reader.onload = function() {
                    that.imgCropperCfg = {
                        img: reader.result,
                        info: true,
                        outputSize: 0.8,
                        outputType: 'jpeg',
                        canScale: true,
                        autoCrop: true,
                        autoCropWidth: 645,
                        autoCropHeight: 360,
                        fixedBox: false,
                        fixed: true,
                        fixedNumber: [1, 1],
                        full: false,
                        canMoveBox: false,
                        original: true,
                        centerBox: false,
                        infoTrue: false,
                        originFileName: file.name,
                        openLocal: true
                    };
                    that.isShowImgCropper = true;
                };
            },
            uploadCroppedImage: function(res) {
                let that = this;

                that.$message.warning('正在上传');

                let data = new FormData();
                data.append('file', res.file);

                that.$http.post(urls.userServer.fileCoverUpload, data).then((res) => {
                    if (res.data.status === 200) {
                        that.form.cover = res.data.data;
                        that.$forceUpdate();
                        that.$message.success('上传完成');
                    }
                });
            },
            showPreviewCoverImage: function() {
                let that = this;

                that.imgCover = that.man.fast.getResourcePath(`cover/${that.form.cover}`, 1);
                that.isShowPreviewCoverImage = true;
            },
            removeCoverImage: function() {
                let that = this;

                that.form.cover = '';
                that.$forceUpdate();
            },

            closed: function() {
                this.form = {
                    name: '',
                    description: '',
                };
            },
        }
    }
</script>



<style lang="less" scoped>
    @deep: ~'>>>';
    #course-management {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            >.search-bar {
                margin: 1vw 0 1vw 2vw;
                width: 90%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.73vw;
                .lf {
                    display: flex;
                    align-items: center;
                    .course-entrybox {
                        background-color: #fff;
                        margin-right: 10px;
                        line-height: 40px;
                        border-radius: 4vw;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #666;
                        .icon-search {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                        span {
                            display: inline-block;
                            margin-left: 10px;
                            padding-right: 10px;
                            border-right: 1px solid #eaeaea;
                        }
                        input {
                            width: 10vw;
                            padding: 0 15px 0 10px;
                        }
                    }
                    .calendar {
                        margin: 0 10px;
                        @{deep} .el-date-editor {
                            border: 0;
                            border-radius: 100px;
                            width: 240px;
                        }
                    }
                    .btn-search {
                        background-color: #24CEFF;
                        color: #fff;
                        line-height: 40px;
                        padding: 0 1.5vw;
                        cursor: pointer;
                        border-radius: 100px;
                        transition: all .3s linear;
                        &:hover {
                            background-color: #129DC6;
                        }
                    }
                }
                .rf {
                    background: #1AAB7D;
                    color: #fff;
                    line-height: 40px;
                    padding: 0 2vw;
                    border-radius: 4vw;
                    cursor: pointer;
                    transition: all .3s linear;
                    &:hover {
                        background-color: #157C5D;
                        box-shadow: 0 2px 5px 1px rgba(60,60,60,.5);
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    overflow: visible;
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;
                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(7) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    .el-table__body-wrapper {
                        overflow: visible;
                        tbody {
                            .el-table__row:last-child {
                                border-radius: 0 0 10px 10px;
                                border-bottom: 0;
                                td:first-child {
                                    border-radius: 0 0 0 10px;
                                }
                                td:last-child {
                                    border-radius: 0 0 10px 0;
                                }
                            }
                            .el-table__row {
                                .cell {
                                    position: relative;
                                    overflow: visible;
                                    .off-shelf {
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                    .off-shelf-reason {
                                        display: none;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        background: #fff;
                                        padding: 6px;
                                        border-radius: 4px;
                                        box-shadow: 0 0 5px 1px #aaa;
                                        width: 200px;
                                        text-align: left;
                                        z-index: 999;
                                    }
                                    &:hover {
                                        .off-shelf-reason {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .dialog {
                @{deep} .dialog-footer {
                    text-align: center;
                }
                @{deep} .el-table {
                    th {
                        background-color: #409EFF;
                        color: #fff;
                    }
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        &::after {
                            border-color: #409EFF;
                        }
                    }
                }
                .courseware {
                    .course {
                        color: #333;
                        span {
                            color: #777;
                        }
                    }
                    label {
                        display: inline-block;
                        margin: 1vw 0;
                    }
                    @{deep} .binded-list {
                        .el-pagination {
                            text-align: right;
                            margin-top: 1vw;
                        }
                    }
                    .binded-list {
                        @{deep} .el-table {
                            margin-top: 1vw;
                            td, th {
                                padding: 0;
                            }
                            th {
                                 background-color: #409EFF;
                                 color: #fff;
                                 height: 45px;
                             }
                        }
                    }
                    .courseware-form {
                        margin-top: 1vw;
                        .search-bar {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            >span {
                                display: inline-block;
                                min-width: 4em;
                                margin-right: 10px;
                                font-size: 0.83vw;
                                color: #333;
                                font-weight: bold;
                            }
                            .el-select {
                                margin-right: 4vw;
                            }
                        }
                        .courseware-pagination {
                            margin-top: 1vw;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        @{deep} .el-table {
                            margin-top: 1vw;
                            td, th {
                                padding: 0;
                            }

                            th {
                                background-color: #409EFF;
                                color: #fff;
                                height: 45px;
                            }
                            .el-checkbox__input.is-checked .el-checkbox__inner {
                                background-color: #fff;
                                &::after {
                                    border-color: #409EFF;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 1.5vw;
                        text-align: center;
                    }
                }
                .course-detail,
                .courseware-detail {
                    @{deep} .el-table {
                        /*margin-top: 1vw;*/
                        td, th {
                            padding: 8px 0;
                        }

                        th {
                            background-color: #409EFF;
                            color: #fff;
                            height: 45px;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner {
                            background-color: #fff;
                            &::after {
                                border-color: #409EFF;
                            }
                        }
                    }
                    >ul {
                        >li {
                            margin-bottom: 20px;
                            color: #606060;
                            label {
                                float: left;
                                min-width: 6em;
                            }
                            div {
                                float: left;
                                width: calc(100% - 6em);
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                        }
                    }
                }
            }
        }
    }

    #js_remark{
        display: none;
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-management {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    .lf{
                        flex-direction: column;
                        width: 100%;
                        .course-entrybox{
                            .fontFn(20,border-radius);
                            width: 100%;
                            background: #F3F3F3;
                            margin-right: 0;
                            margin-bottom: 5%;
                            .fontFn(38,line-height);
                            .icon-search{
                                .fontFn(18,width);
                                .fontFn(18,height);
                                margin-left: 16px;
                            }
                            span{
                                color:#555859;
                                .fontFn(14);
                                .fontFn(26,line-height);
                            }
                            input{
                                width: auto;
                                flex-grow:1;
                                .fontFn(14);
                            }
                        }
                        .course-status-options{
                            width: 100%;
                            margin-bottom: 5%;

                            @{deep} .el-select{
                                width: 100%;
                            }

                            @{deep} .el-input__inner{
                                padding: 0 32px 0 16px;
                                .fontFn(14);
                                .fontFn(20,border-radius);
                                border:none;
                                background: #F3F3F3;
                                .fontFn(38,height);
                                .fontFn(38,line-height);
                            }
                        }
                        .calendar{
                            width: 100%;
                            margin:0 auto 5%;
                            .el-date-editor{
                                padding: 0;
                                width: 100%;
                                background: #F3F3F3;
                                .fontFn(20,border-radius);
                                @{deep} .el-range__icon{
                                    .fontFn(18);
                                    height: 32px;
                                    margin-left: 16px;
                                }
                                @{deep} .el-range-separator{
                                    height: 10px;
                                    line-height: 10px;
                                }
                                @{deep} .el-range-input{
                                    .fontFn(38,line-height);
                                    .fontFn(14);
                                }
                            }
                        }
                        .btn-search{
                            width: 100%;
                            text-align: center;
                            .fontFn(14);
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                            margin-bottom: 5%;
                        }
                    }
                    .rf{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                    }
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }

                .el-dialog__wrapper{
                    @{deep} .el-dialog{
                        width: 96% !important;
                    }

                    @{deep} .el-dialog__body{
                        padding:20px 10px;
                    }

                    @{deep} .el-form-item__label{
                        width: 80px !important;
                    }

                    @{deep} .el-form-item__content{
                        margin-left:80px !important;
                    }

                    @{deep} .el-textarea__inner{
                        min-height: 120px !important;
                    }

                    @{deep} .el-row{
                        img{
                            width: 320px !important;
                            display: block;
                            margin:0 auto;
                        }
                    }
                }

                .dialog{
                    .courseware{
                        label{margin-bottom:3%;}
                        .courseware-form{
                            .search-bar{
                                flex-direction: column;
                                &>span{
                                    display: none;
                                }
                                .el-input{
                                    margin-bottom: 3%;
                                }
                                .el-range-editor.el-input__inner{
                                    width: 100% !important;
                                    margin:0 auto 3% !important;
                                }
                                .el-button{
                                    width: 100%;
                                    margin-bottom: 3%;
                                }
                            }
                        }
                    }
                }
            }
            
        }

        #js_remark{
            display: list-item;
        }
    }
</style>

<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>